import { useEffect } from "react"
import type { Metric } from "web-vitals"

export interface AnalyticsData {
  page: string
  href: string
  metric: Metric
}

async function sendToAnalytics(metric: Metric) {
  const data: AnalyticsData = {
    page: window.location.pathname,
    href: window.location.href,
    metric
  }
  const body = JSON.stringify(data)

  const doSendViaFetch = async () => {
    return fetch("/analytics/performance", {
      body,
      method: "POST",
      keepalive: true
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (navigator.sendBeacon) {
    try {
      navigator.sendBeacon("/analytics/performance", body)
    } catch {
      // Fallback to fetch
      await doSendViaFetch()
    }
  } else {
    await doSendViaFetch()
  }
}

export function useWebVitals() {
  useEffect(() => {
    async function handleWebVitals() {
      const {
        onCLS: onCls,
        onINP: onInp,
        onLCP: onLcp,
        onFCP: onFcp,
        onTTFB: onTtfb
      } = await import("web-vitals")

      onCls(sendToAnalytics)
      onInp(sendToAnalytics)
      onLcp(sendToAnalytics)
      onFcp(sendToAnalytics)
      onTtfb(sendToAnalytics)
    }

    const idleRunner = () => {
      handleWebVitals().catch((error: unknown) => {
        console.error("Failed to load web-vitals", error)
      })
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (window.requestIdleCallback) {
      window.requestIdleCallback(idleRunner)
    } else {
      setTimeout(idleRunner, 1)
    }
  }, [])
}
